.form-array-border {
    margin-bottom: 10px;
}

.input-disabled {
    background-color: #f0f0f0;
    width: 100%;
}

.input-full-width {
    width: 100%;
}

.input-padding-right {
    padding-right: 0px;
}

.input-padding-left {
    padding-left: 0px;
}

.input-margin-right {
    margin-right: 8px;
}

.input-margin-left {
    margin-left: 8px;
}
