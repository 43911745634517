.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.popup-box {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: 400px;
}


@media (max-width: 768px) {
    .popup-box {
        width: 70%;
        max-width: 300px;
        padding: 15px;
    }
}

.popup-buttons {
    margin-top: 15px;
}

.popup-button {
    padding: 10px 15px;
    margin: 0 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.popup-button.confirm {
    background-color: #28a745;
    color: white;
}

.popup-button.cancel {
    background-color: #dc3545;
    color: white;
}