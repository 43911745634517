.vehicle-part-image {
    border: 0;
    margin-left: 10px;
}

.vehicle-part-style {
    box-shadow:
        rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
        rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding-bottom: 3px;
    border-radius: 5px;
    margin: 6px 8px;
    display: inline-block;
    position: relative;
    width: 100%; /* Ensure the container takes up the full width */
    max-width: 300px; /* Set a max-width for the container */
    text-align: center; /* Center the content inside */
}

.vehicle-part-style img {
    width: 100%; /* Make the image fill the container */
    height: auto; /* Maintain aspect ratio */
    object-fit: contain; /* Prevent distortion */
    max-width: 200px; /* Set a max width for the image */
    display: block; /* Ensure proper alignment */
    margin: 0 auto; /* Center the image horizontally */
}

.selected-part img {
    width: 100%; /* Ensure the image takes up the full width of the container */
    height: auto; /* Maintain aspect ratio */
    object-fit: contain; /* Prevent distortion */
    max-width: 300px; /* Limit max width for the image */
    margin: 0 auto;
    display: block; /* Center the image */
}


.carParts-name {
    font-weight: bold;
    text-align: center;
    margin-bottom: 0 !important;
}
.vehicle-parts-remove {
    color: red;
    font-size: 24px;
    margin-left: 40%;
    margin-right: 40%;
    cursor: pointer;
}

.vehicle-parts-remove:hover {
    color: rgb(255, 102, 0);
    font-size: 24px;
    margin-left: 40%;
    margin-right: 40%;
    cursor: pointer;
}
/* .modify-btn{
    background-color: ;
} */

.partName-style {
    background-color: black;
    color: white;
    padding: 7px 10px;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 10px !important;
    font-size: 0.8em;
}
.quantity-style {
    font-size: 16px;
    margin-bottom: 10px !important;
}
.card-style-part {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
