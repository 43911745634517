/*======================================
//--//-->   STYLES GENERALES
======================================*/

body {
    background-color: #f5f5f5;
    color: #4e4e4e;
}
li {
    list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #1e1e1e;
}

a {
    color: #1e1e1e;
    transition: all 0.5s ease-in-out;
}

a:hover {
    color: #0078ff;
    text-decoration: none;
    transition: all 0.5s ease-in-out;
}

.p-r {
    position: relative;
}

.color-a {
    color: #0078ff;
}

.color-d {
    color: #f5f5f5;
}

.color-text-a {
    color: #4e4e4e;
}

.box-shadow,
.paralax-mf,
.service-box,
.work-box,
.card-blog {
    box-shadow: 0 13px 8px -10px rgba(0, 0, 0, 0.1);
}

.box-shadow-a,
.button:hover {
    box-shadow: 0 0 0 4px #cde1f8;
}

.display-5 {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.1;
}

.display-6 {
    font-size: 2rem;
    font-weight: 300;
    line-height: 1.1;
}

.avatar {
    width: 32px;
    height: 32px;
    margin-right: 4px;
    overflow: hidden;
}

.bg-image {
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center center;
}

.overlay-mf {
    background-color: #0078ff;
}

.overlay-mf {
    position: absolute;
    top: 0;
    left: 0px;
    padding: 0;
    height: 100%;
    width: 100%;
    opacity: 0.7;
}

.paralax-mf {
    position: relative;
    padding: 8rem 0;
}

.display-table {
    width: 100%;
    height: 100%;
    display: table;
}

.table-cell {
    display: table-cell;
    vertical-align: middle;
}

/*--/ Sections /--*/

.sect-4 {
    padding: 4rem 0;
}

.sect-pt4 {
    padding-top: 4rem;
}

.sect-mt4 {
    margin-top: 4rem;
}

/*--/ Title s /--*/

.title-s {
    font-weight: 600;
    color: #1e1e1e;
    font-size: 1.1rem;
}

/*--/ Title A /--*/

.title-box {
    margin-bottom: 4rem;
}

.title-a {
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
}

.subtitle-a {
    color: #4e4e4e;
}

.line-mf {
    width: 40px;
    height: 5px;
    background-color: #0078ff;
    margin: 0 auto;
}

/*--/ Title Left /--*/

.title-box-2 {
    margin-bottom: 3rem;
}

.title-left {
    font-size: 2rem;
    position: relative;
}

.title-left:before {
    content: "";
    position: absolute;
    height: 3px;
    background-color: #0078ff;
    width: 100px;
    bottom: -12px;
}

/*------/ Box /------*/

.box-pl2 {
    padding-left: 2rem;
}

.box-shadow-full {
    padding: 3rem 1.25rem;
    position: relative;
    background-color: #fff;
    margin-bottom: 3rem;
    z-index: 2;
    box-shadow:
        0 1px 1px 0 rgba(0, 0, 0, 0.06),
        0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

/*------/ Socials /------*/

.socials {
    padding: 1.5rem 0;
}

.socials ul li {
    display: inline-block;
}

.socials .ico-circle {
    height: 40px;
    width: 40px;
    font-size: 1.7rem;
    border-radius: 50%;
    line-height: 1.4;
    margin: 0 15px 0 0;
    box-shadow: 0 0 0 3px #0078ff;
    transition: all 500ms ease;
    display: flex;
    justify-content: center;
    align-items: center;
}

.socials .ico-circle:hover {
    background-color: #0078ff;
    color: #fff;
    box-shadow: 0 0 0 3px #cde1f8;
    transition: all 500ms ease;
}

/*------/ Ul resect /------*/

.ul-resect,
.socials ul,
.list-ico,
.blog-wrapper .post-meta ul,
.box-comments .list-comments,
.widget-sidebar .list-sidebar,
.widget-tags ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.list-ico {
    line-height: 2;
}

.list-ico .contact-icon {
    color: #0078ff;
    margin-right: 10px;
}

/*------/ Ico Circle /------*/

.ico-circle {
    height: 100px;
    width: 100px;
    font-size: 2rem;
    border-radius: 50%;
    line-height: 1.55;
    margin: 0 auto;
    text-align: center;
    box-shadow: 0 0 0 10px #0078ff;
    display: block;
}

/*------/ Owl Carousel /------*/

.owl-theme .owl-dots {
    text-align: center;
    margin-top: 18px;
}

.owl-theme .owl-dots .owl-dot {
    display: inline-block;
}

.owl-theme .owl-dots .owl-dot span {
    width: 18px;
    height: 7px;
    margin: 5px 5px;
    background: #cde1f8;
    border: 0px solid #cde1f8;
    display: block;
    transition: all 0.6s ease-in-out;
    cursor: pointer;
}

.owl-theme .owl-dots .owl-dot:hover span {
    background-color: #cde1f8;
}

.owl-theme .owl-dots .owl-dot.active span {
    background-color: #1b1b1b;
    width: 25px;
}

/*--/ Scrolltop s /--*/

.scrolltop-mf {
    position: relative;
    display: none;
}

.scrolltop-mf span {
    z-index: 999;
    position: fixed;
    width: 42px;
    height: 42px;
    background-color: #0078ff;
    opacity: 0.7;
    font-size: 1.6rem;
    line-height: 1.5;
    text-align: center;
    color: #fff;
    top: auto;
    left: auto;
    right: 30px;
    bottom: 50px;
    cursor: pointer;
    border-radius: 50%;
}

/* Back to top button */

.back-to-top {
    position: fixed;
    display: block;
    background: #0078ff;
    color: #fff;
    width: 44px;
    height: 44px;
    text-align: center;
    line-height: 44px;
    font-size: 16px;
    border-radius: 50%;
    right: 15px;
    bottom: 15px;
    transition: background 0.5s;
    z-index: 11;
    cursor: pointer;
}

.back-to-top .top-icon {
    font-size: 24px;
    color: #fff;
}

/* Prelaoder */

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    overflow: hidden;
    background: #fff;
}

#preloader:before {
    content: "";
    position: fixed;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    border: 6px solid #f2f2f2;
    border-top: 6px solid #0078ff;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    -webkit-animation: animate-preloader 1s linear infinite;
    animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes animate-preloader {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/*======================================
//--//-->   NAVBAR
======================================*/

.navbar-b {
    padding-bottom: 1.563rem;
    padding-top: 1.563rem;
    transition: all 0.5s ease-in-out;
    background-color: transparent;
    display: inline-block;
}

.navbar-b.navbar-reduce {
    box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.06);
}

.navbar-brand img {
    width: 130px;
}

.navbar-b.navbar-trans .nav-item,
.navbar-b.navbar-reduce .nav-item {
    position: relative;
    /* padding-right: 7px; */
    padding-left: 0;
    color: #1b1b1b;
}

.navbar-b.navbar-trans .nav-link,
.navbar-b.navbar-reduce .nav-link {
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
}

.navbar-b.navbar-trans .nav-link:before,
.navbar-b.navbar-reduce .nav-link:before {
    content: "";
    position: absolute;
    margin-left: 0px;
    width: 0%;
    bottom: 0;
    left: 0;
    height: 2px;
    transition: all 500ms ease;
}

.navbar-b.navbar-trans .nav-link:hover,
.navbar-b.navbar-reduce .nav-link:hover {
    color: #1b1b1b;
}

.navbar-b.navbar-trans .nav-link:hover:before,
.navbar-b.navbar-reduce .nav-link:hover:before {
    width: 35px;
}

.navbar-b.navbar-trans .show > .nav-link:before,
.navbar-b.navbar-trans .active > .nav-link:before,
.navbar-b.navbar-trans .nav-link.show:before,
.navbar-b.navbar-trans .nav-link.active:before,
.navbar-b.navbar-reduce .show > .nav-link:before,
.navbar-b.navbar-reduce .active > .nav-link:before,
.navbar-b.navbar-reduce .nav-link.show:before,
.navbar-b.navbar-reduce .nav-link.active:before {
    width: 35px;
}

.navbar-b.navbar-trans .nav-link:before {
    background-color: #fff;
}

.navbar-b.navbar-trans .nav-link:hover {
    color: #fff;
}

.navbar-b.navbar-trans .show > .nav-link,
.navbar-b.navbar-trans .active > .nav-link,
.navbar-b.navbar-trans .nav-link.show,
.navbar-b.navbar-trans .nav-link.active {
    color: #fff;
}

.navbar-b.navbar-reduce {
    transition: all 0.5s ease-in-out;
    background-color: #fff;
    padding-top: 15px;
    padding-bottom: 15px;
}

.navbar-b.navbar-reduce .nav-link {
    color: #0078ff;
}

.navbar-b.navbar-reduce .nav-link:before {
    background-color: #0078ff;
}

.navbar-b.navbar-reduce .nav-link:hover {
    color: #0078ff;
}

.navbar-b.navbar-reduce .show > .nav-link,
.navbar-b.navbar-reduce .active > .nav-link,
.navbar-b.navbar-reduce .nav-link.show,
.navbar-b.navbar-reduce .nav-link.active {
    color: #0078ff;
}

.navbar-b.navbar-reduce .navbar-brand {
    color: #0078ff;
}

.navbar-b.navbar-reduce .navbar-toggler span {
    background-color: #1b1b1b;
}

.navbar-b .navbar-brand {
    color: #fff;
    font-size: 1.6rem;
    font-weight: 600;
}

.navbar-b .navbar-nav .dropdown-item.show .dropdown-menu,
.navbar-b .dropdown.show .dropdown-menu,
.navbar-b .dropdown-btn.show .dropdown-menu {
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
    visibility: visible !important;
}

.navbar-b .dropdown-menu {
    margin: 1.12rem 0 0;
    border-radius: 0;
}

.navbar-b .dropdown-menu .dropdown-item {
    padding: 0.7rem 1.7rem;
    transition: all 500ms ease;
}

.navbar-b .dropdown-menu .dropdown-item:hover {
    background-color: #0078ff;
    color: #fff;
    transition: all 500ms ease;
}

.navbar-b .dropdown-menu .dropdown-item.active {
    background-color: #0078ff;
}
.avatar {
    position: relative;
}
.profile-information {
    position: absolute;
    top: 100%;
    left: 50px;
    transform: translateX(-50%);
    z-index: 1000;
}
.avatar img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
}
.profile-information {
    width: 120px;
    padding: 20px;
    padding-top: 5px;
    border-radius: 9px;
    margin-top: 5px;
    background-color: #fefefe;
    box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.1);
}
.profile-information-link {
    display: block;
    color: #111;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 10px;
}
/*--/ Hamburger Navbar /--*/

.navbar-toggler {
    position: relative;
}

.navbar-toggler:focus,
.navbar-toggler:active {
    outline: 0;
}

.navbar-toggler span {
    display: block;
    background-color: #fff;
    height: 3px;
    width: 25px;
    margin-top: 4px;
    margin-bottom: 4px;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    left: 0;
    opacity: 1;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
    transition: -webkit-transform 0.35s ease-in-out;
    transition: transform 0.35s ease-in-out;
    transition:
        transform 0.35s ease-in-out,
        -webkit-transform 0.35s ease-in-out;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
    position: absolute;
    left: 12px;
    top: 10px;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    opacity: 0.9;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
    height: 12px;
    visibility: hidden;
    background-color: transparent;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
    position: absolute;
    left: 12px;
    top: 10px;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    opacity: 0.9;
}

.drop-icon .icon {
    font-size: 24px;
    color: #fff;
    cursor: pointer;
}
.navbar-b.navbar-reduce .drop-icon .icon {
    color: #0062d3;
}

/* sub menu style  */
.nav-link {
    position: relative;
    /* width: 100%; */
}
.mega-menu-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    z-index: 1000;
}

.nav-item:hover .mega-menu-wrapper {
    display: block;
}

.menu-items li {
    text-transform: capitalize;
    padding: 0.2rem 0;
    margin: 0.2rem 0;
    font-size: 0.95rem;
}
.menu-items li a {
    opacity: 0.8;
    transition: all 0.5s ease;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    color: #333;
}
.menu-items li a:hover {
    padding-left: 14px;
    opacity: 0.9;
}
.sub-menu {
    display: block;
}

.sub-menu-item {
    padding-left: 1.2rem;
}

/* sub menu end  */

/*======================================
//--//-->   INTRO
======================================*/

.intro {
    height: 100vh;
    position: relative;
    color: #fff;
}

.intro .intro-content {
    text-align: center;
    position: absolute;
}

.intro .overlay-itro {
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0px;
    padding: 0;
    height: 100%;
    width: 100%;
    opacity: 0.9;
}

.intro .intro-title {
    color: #fff;
    font-weight: 600;
    font-size: 3rem;
}

.intro .intro-subtitle {
    font-size: 1.5rem;
    font-weight: 300;
}

.intro .text-slider-items {
    display: none;
}

.intro-single {
    height: 350px;
}

.intro-single .intro-content {
    margin-top: 30px;
}

.intro-single .intro-title {
    text-transform: uppercase;
    font-size: 3rem;
}

.intro-single .breadcrumb {
    background-color: transparent;
    color: #0078ff;
}

.intro-single .breadcrumb .breadcrumb-item:before {
    color: #cde1f8;
}

.intro-single .breadcrumb .breadcrumb-item.active {
    color: #cde1f8;
}

.intro-single .breadcrumb a {
    color: #fff;
}

/*======================================
//--//-->   ABOUT
======================================*/

.about-mf .box-shadow-full {
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.about-mf .about-img {
    margin-bottom: 2rem;
}

.about-mf .about-img img {
    /* margin-left: 10px;  */
    height: 150px;
}

.skill-mf span {
    color: #4e4e4e;
}

.skill-mf .progress {
    background-color: #cde1f8;
    margin: 0.5rem 0 1.2rem 0;
    border-radius: 0;
    height: 0.7rem;
}

.skill-mf .progress .progress-bar {
    height: 0.7rem;
    background-color: #0078ff;
}

/*======================================
//--//-->   SERVICES
======================================*/

.service-box {
    background-color: #fff;
    border-radius: 0 0 20px 20px;
    margin-bottom: 3rem;
    cursor: pointer;
}

.service-box:hover .ico-circle {
    transition: all 500ms ease;
    color: #fff;
    background-color: #0078ff;
    box-shadow: 0 0 0 10px #cde1f8;
}

.service-box .service-ico {
    margin-bottom: 1rem;
    color: #1e1e1e;
}

.service-box .ico-circle {
    transition: all 500ms ease;
    font-size: 4rem;
}

.service-box .s-title {
    font-size: 1.4rem;
    text-transform: uppercase;
    text-align: center;
    padding: 0.4rem 0;
}

.service-box .s-description {
    color: #4e4e4e;
}

.rates {
    background-color: rgb(165, 154, 154);
    border-radius: 0px 0px 20px 20px;
}

.rates h1,
sub,
sup {
    color: white;
}

.rates:hover {
    transition: all 500ms ease;
    background-color: #0078ff;
}

/* slide-down-section  */
/* boostrap class edit  */
.accordion-button:focus {
    outline: none;
    border: none;
}
.accordion-body {
    background: #ddd;
}
.accordion-item {
    border: 1px solid #ccc;
    padding: 0;
}
.accordion-header {
    background-color: #f5f5f5 !important;
    padding: 0px !important;

    /* margin-bottom: 15px; */
}
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
    outline: none;
    border: none;
}
.accordion-button {
    background-color: #f5f5f5 !important;
    border: 0;
    border-radius: 0;
    outline: 0 !important;
}

.accordion {
    border: 1px solid #ccc;
    margin-bottom: 20px;
    background-color: #dddddd;
}

.accordion-header {
    background-color: #f5f5f5;
    padding: 10px;
    display: flex;
    align-items: center;
}

.accordion-plus-icons {
    font-size: 1.5rem;
    margin-right: 15px;
}
.accordion-toggle {
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out 0.2s;
    padding: 0 10px;
}

.accordion.open .accordion-content {
    max-height: 100%;
}

.courses-img {
    width: 130px;
    padding: 20px;
}

.technology-card {
    width: 270px;
    border: 1px solid #d3cfcf;
    border-radius: 5px;
}
.technology-card-title {
    padding: 20px 10px;
}
.technology-card-title h2 {
    color: #0078ff;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}
.technology-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}
.technology-content {
    padding: 20px 10px;
}
.technology-content span {
    display: block;
    font-size: 16px;
    color: #777;
}
.technology-content .right-icon {
    color: forestgreen;
    font-size: 20px;
}
.card-button button {
    outline: none;
    border: none;
    display: block;
    background: #0078ff;
    color: #fff;
    width: 100%;
    text-transform: capitalize;
    font-size: 18px;
    padding: 8px 0;
    border-radius: 5px;
    font-weight: 500;
}
/* Privacy  */

.privacy-image img {
    margin-top: 150px !important;
    height: 350px !important;
}
.lead {
    text-align: justify;
}
/*======================================
//--//-->   COUNTER
======================================*/

.counter-box {
    color: #fff;
    text-align: center;
}

.counter-ico {
    margin-bottom: 1rem;
}

.counter-ico .ico-circle {
    height: 60px;
    width: 60px;
    line-height: 1.8;
    box-shadow: 0 0 0 10px #cde1f8;
}

.counter-num .counter {
    font-size: 2rem;
    margin-bottom: 0;
}

/*======================================
//--//-->   PORTFOLIO
======================================*/

.work-box {
    margin-bottom: 3rem;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-color: #fff;
}

.work-box:hover img {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
}

.work-img {
    display: block;
    overflow: hidden;
}

.work-img img {
    transition: all 1s;
}

.work-content {
    padding: 2rem 3% 1rem 4%;
}

.work-content .w-more {
    color: #4e4e4e;
    font-size: 0.8rem;
}

.work-content .w-more .w-ctegory {
    color: #0078ff;
}

.work-content .w-like {
    font-size: 2.5rem;
    color: #0078ff;
    float: right;
}

.work-content .w-like a {
    color: #0078ff;
}

.work-content .w-like .num-like {
    font-size: 0.7rem;
}

.w-title {
    font-size: 1.2rem;
}

/*======================================
//--//-->   TESTIMONIALS
======================================*/

.testimonials .owl-carousel .owl-item img {
    width: auto;
}

.testimonial-box {
    color: #fff;
    text-align: center;
}

.testimonial-box .author-test {
    margin-top: 1rem;
}

.testimonial-box .author-test img {
    margin: 0 auto;
}

.testimonial-box .author {
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    margin: 1rem 0;
    display: block;
    font-size: 1.4rem;
}

.testimonial-box .comit {
    font-size: 2rem;
    color: #0078ff;
    background-color: #fff;
    width: 52px;
    height: 52px;
    display: block;
    margin: 0 auto;
    border-radius: 50%;
    line-height: 1.6;
}

/*======================================
//--//-->   BLOG
======================================*/

.card-blog .card-body {
    position: relative;
}

.card-blog .card-category-box {
    position: absolute;
    text-align: center;
    top: -16px;
    left: 15px;
    right: 15px;
    line-height: 25px;
    overflow: hidden;
}

.card-blog .card-category {
    display: inline-block;
    color: #fff;
    padding: 0 15px 5px;
    overflow: hidden;
    background-color: #0078ff;
    border-radius: 4px;
}

.card-blog .card-category .category {
    color: #fff;
    display: inline-block;
    text-transform: uppercase;
    font-size: 0.7rem;
    letter-spacing: 0.1px;
    margin-bottom: 0;
}

.card-blog .card-title {
    font-size: 1.3rem;
    margin-top: 0.6rem;
}

.card-blog .card-description {
    color: #4e4e4e;
}

.card-blog .post-author {
    display: inline-block;
}

.card-blog .post-date {
    color: #4e4e4e;
    display: inline-block;
    float: right;
}

/*======================================
//--//-->   BLOG-SINGLE
======================================*/

.post-box,
.form-comments,
.box-comments,
.widget-sidebar {
    padding: 2rem;
    background-color: #fff;
    margin-bottom: 3rem;
}

.blog-wrapper .article-title {
    font-size: 1.5rem;
}

.blog-wrapper .post-meta {
    margin: 1rem 0;
}

.blog-wrapper .post-meta ul {
    border-left: 4px solid #0078ff;
    margin-top: 1rem;
}

.blog-wrapper .post-meta ul li {
    display: inline-block;
    margin-left: 15px;
}

.blog-wrapper .post-meta ul a {
    color: #0078ff;
}

.blog-wrapper .post-meta ul span {
    color: #1e1e1e;
}

.blog-wrapper .blockquote {
    border-left: 4px solid #0078ff;
    padding: 18px;
    font-style: italic;
}

/*------/ Comments /------*/

.box-comments .list-comments li {
    padding-bottom: 40px;
}

.box-comments .list-comments .comment-avatar {
    display: table-cell;
    vertical-align: top;
}

.box-comments .list-comments .comment-avatar img {
    width: 80px;
    height: 80px;
}

.box-comments .list-comments .comment-author {
    font-size: 1.3rem;
}

.box-comments .list-comments .comment-details {
    display: table-cell;
    vertical-align: top;
    padding-left: 25px;
}

.box-comments .list-comments a {
    color: #0078ff;
}

.box-comments .list-comments span {
    color: #1e1e1e;
    font-style: italic;
}

.box-comments .comment-children {
    margin-left: 40px;
}

/*------/ Sidebar /------*/

.widget-sidebar .sidebar-title {
    font-size: 1.6rem;
    font-weight: 600;
    border-left: 5px solid #0078ff;
    padding-left: 15px;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
}

.widget-sidebar .list-sidebar li {
    position: relative;
    padding: 6px 0 6px 24px;
}

.widget-sidebar .list-sidebar li:before {
    position: absolute;
    content: "";
    width: 10px;
    height: 1px;
    left: 0;
    background-color: #0078ff;
    top: 20px;
}

.sidebar-search input {
    background-color: #fff;
    border-radius: 0;
    transition: all 0.5s ease-in-out;
}

.sidebar-search .btn-search {
    background-color: #0078ff;
    border-color: #0078ff;
    border-radius: 0;
    padding-left: 20px;
    padding-right: 20px;
}

.widget-tags ul li {
    display: inline-block;
    background-color: #0078ff;
    padding: 0.2rem 0.6rem;
    margin-bottom: 0.5rem;
    border-radius: 15px;
}

.widget-tags ul li a {
    color: #fff;
}

/*======================================
//--//-->   CONTACT
======================================*/

.rc-anchor-logo-portrait {
    margin: 10px 0 0 -40px !important;
}
.footer-paralax {
    padding: 4rem 0 0 0;
}

.contact-mf {
    margin-top: 4rem;
}

/*======================================
//--//-->   FOOTER
======================================*/

footer {
    text-align: center;
    color: #fff;
    padding-bottom: 4rem;
}

.copyright {
    margin-bottom: 0.3rem;
    color: #fff;
    position: abo;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.copyright-box {
    z-index: 100;
}

footer .credits {
    margin-bottom: 0;
}

footer .credits a {
    color: #fff;
    z-index: 10;
}

/*======================================
//--//-->   CONtaCT FORM
======================================*/

#sendmessage {
    color: #0078ff;
    border: 1px solid #0078ff;
    display: none;
    text-align: center;
    padding: 15px;
    font-weight: 600;
    margin-bottom: 15px;
}

#errormessage {
    color: red;
    display: none;
    border: 1px solid red;
    text-align: center;
    padding: 15px;
    font-weight: 600;
    margin-bottom: 15px;
}

#sendmessage.show,
#errormessage.show,
.show {
    display: block;
}

.validation {
    color: red;
    display: none;
    margin: 0 0 20px;
    font-weight: 400;
    font-size: 13px;
}

/*======================================
//--//-->   BUTTON
======================================*/

.button {
    display: inline-block;
    padding: 0.3rem 0.6rem;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 1rem;
    border-radius: 0.3rem;
    border: 1px solid transparent;
    transition: all 500ms ease;
    cursor: pointer;
}

.button:focus {
    outline: 0;
}

.button:hover {
    background-color: #0062d3;
    color: #fff;
    transition: all 500ms ease;
}

.button-a {
    background-color: #0078ff;
    color: #fff;
    border-color: #cde1f8;
}
.button-a:disabled {
    opacity: 0.8;
    cursor: default;
}

.button-big {
    padding: 0.9rem 2.3rem;
    font-size: 1.2rem;
}

.button-rouded {
    border-radius: 5rem;
}

.btn-lg {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}
.logo-style {
    height: 100px;
    width: 250px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
@media (max-width: 577px) {
    .counter-box {
        margin-bottom: 1.8rem;
    }

    .about-mf .about-img img {
        height: 150px;
        width: 50% !important;
    }
    .privacy-image img {
        margin-top: 0px !important;
        height: 250px !important;
    }
    .avatar-li {
        position: relative;
    }
}

@media (min-width: 577px) {
    .counter-box {
        margin-bottom: 1.8rem;
    }
}

@media (max-width: 767px) {
    .privacy-image img {
        margin-top: 0px !important;
        height: 250px !important;
    }
    .profile-information {
        position: absolute;
        top: 0;
        left: 105px;
    }
    .navbar-nav .dropdown-menu {
        position: absolute !important;
        left: 0;
    }
    .mega-menu-wrapper {
        left: 40%;
        top: 65%;
    }
}

@media (min-width: 767px) {
    .about-mf .box-pl2 {
        margin-top: 3rem;
        padding-left: 0rem;
    }

    .card-blog {
        margin-bottom: 3rem;
    }

    .contact-mf .box-pl2 {
        margin-top: 3rem;
        padding-left: 0rem;
    }
}

@media (min-width: 768px) {
    .box-shadow-full {
        padding: 3rem;
    }

    .navbar-b.navbar-trans .nav-item,
    .navbar-b.navbar-reduce .nav-item {
        padding-left: 10px;
    }

    .navbar-b.navbar-trans .nav-link:before,
    .navbar-b.navbar-reduce .nav-link:before {
        margin-left: 18px;
    }

    .intro .intro-title {
        font-size: 4.5rem;
    }

    .intro .intro-subtitle {
        font-size: 2.5rem;
    }

    .intro-single .intro-title {
        font-size: 3.5rem;
    }

    .testimonial-box .description {
        padding: 0 5rem;
    }

    .post-box,
    .form-comments,
    .box-comments,
    .widget-sidebar {
        padding: 3rem;
    }

    .blog-wrapper .article-title {
        font-size: 1.9rem;
    }

    .box-comments .list-comments .comment-author {
        font-size: 1.5rem;
    }
}

@media (min-width: 992px) {
    .testimonial-box .description {
        padding: 0 8rem;
    }
}

@media (min-width: 1200px) {
    .testimonial-box .description {
        padding: 0 13rem;
    }
}

@media (max-width: 768px) {
    .back-to-top {
        bottom: 15px;
    }
    .navbar-toggler span {
        display: block;
        background-color: grey;
        height: 3px;
        width: 25px;
        margin-top: 4px;
        margin-bottom: 4px;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        left: 0;
        opacity: 1;
    }
    .login-wrapper {
        margin-top: 80px;
    }
}

.error-message {
    font-size: 14px;
    color: red;
    text-align: center;
}
.nav-item > a {
    color: #111 !important;
}
@media screen and (max-width: 1020px) and (min-width: 769px) {
    .navbar > .container,
    .navbar > .container-fluid,
    .navbar > .container-lg,
    .navbar > .container-md,
    .navbar > .container-sm,
    .navbar > .container-xl,
    .navbar > .container-xxl {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
}
