.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  width: 100%;
  position: relative;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-header {
  display: flex;
  justify-content: left;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px;
  text-align: right;
}

.modal-header img {
    width: 15%;
    margin-right: 10px; 
}

.modal-payment img {
    width: 5%;
    margin-right: 10px; 
}


/* SECTION - DOWNLOAD BUTTON */
.download-button {
  background: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
}

.download-button-container {
    text-align: right;
}



/* SECTION - INVOICE */
.invoice-details {
  margin-bottom: 20px;
}

.invoice-details p {
    margin: 0; /* Remove any default margins */
    padding: 0; /* Remove any default padding */
}

.invoice-details .invoice-info {
  display: flex;
  flex-direction: column;
}

.invoice-details .label {
  font-weight: bold;
  color: #007bff;
}

.invoice-details .value {
  margin-left: 5px;
}

.invoice-info {
    display: flex;
    flex-direction: column;
}

.info-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px; /* Adjust space between rows if needed */
    margin-left: 0px;
}

.invoice-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.invoice-table th,
.invoice-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.invoice-table th {
  background-color: #5f87e3;
  color: white;
}

.invoice-total {
    display: flex;
    flex-direction: column;
    text-align: right;
    font-size: 18px;
    font-weight: normal;
}

.invoice-total div {
  margin-bottom: 10px;
}


.label-with-image {
    display: flex;
    align-items: center;
}

.payment-image {
    width: 15%;
    margin-right: 10px; /* Space between the image and the text */
}

.label {
    margin-bottom: 5px; /* Adjust the space between rows if needed */
}



/* SECTION - TEXT  */
.text-right {
    text-align: right;
}


.text-left {
    text-align: left;
}

.bold-text {
    font-weight: bold;
}

.title {
    text-align: right;
    flex: 1;
    padding-right: 10px; /* Space between label and value */
}

.value {
    text-align: left;
    flex: 2;
    font-weight: bold;
}