

/*********************** CONTAINERS *********************************************/



/******************************************************************************/




#outputBox {
    resize: vertical; /* Allows only horizontal resizing */
    max-width: 790px; /* Approximation for 80 columns, adjust as needed */
    min-height: 130px; /* Sets the minimum height to 130px */
}

#charCount {
    position: absolute;
    bottom: -20px; /* Adjust the value to position the count correctly */
    right: 0;
    padding: 5px;
    background-color: #f0f0f0; /* Optional: adds background color to make the count stand out */
    border-radius: 5px; /* Optional: rounds the corners of the character count display */
    color: #333; /* Optional: sets the text color */
    font-size: 0.9em; /* Optional: adjusts the size of the text */
}

.title {
    color: #003366;
    text-align: center;
}

.transcription-output {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    padding: 15px;
    margin: 20px 0;
}

.download-link {
    text-decoration: none;
    color: #003366;
    padding: 5px 10px;
    margin-bottom: 20px;
}

#spinner img {
width: 50px; /* Change this value to the desired width */
height: auto; /* This will keep the aspect ratio intact */
}

.blue-banner {
    background-color: #007bff; /* Example blue color, adjust to match your actual banner color */
    color: white;
    padding: 10px; /* Example padding, adjust to match your actual banner styles */
    /* Add other styles that match the "Audio File information" banner */
}

.transcription-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    }

    #wordCount {
        margin-left: auto; /* This will push the word count to the right */
    }




/*********************** BUTTONS *********************************************/
    .green-button {
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    }

    .blue-button {
        padding: 10px 20px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }

    .red-button {
        padding: 10px 20px;
        background-color: #ff0000;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }

    .green-button:hover {
    background-color: #218838;
    }


    .button-space {
        margin-right: 10px;
    }




/*********************** FILE SECTION *********************************************/    
    .file-input {
        margin-top: 20px;
        text-align: center;
        }
    
        /* New styles for the "Choose File" label */
        .file-input label {
            background-color: #007bff; /* Bootstrap primary blue */
            color: white;
            padding: 10px 20px;
            border-radius: 5px;
            display: inline-block;
            cursor: pointer;
        }
    
        .file-input label:hover {
            background-color: #0069d9; /* A slightly darker shade of blue for hover effect */
        }
    
        .file-input input[type="file"] {
            display: none;
        }
    
        .file-input button {
            display: block;
            margin: auto;
            padding: 10px 20px;
            background-color: #28a745;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
        }
    
        .file-input button:hover {
            background-color: #218838;
        }
    
            /* Submit button styles */
        .file-input button.submit {
        background-color: #28a745; /* This is already the color for green */
        }
    
        /* New styles for the 'Choose Audio File' button */
        .file-input button.choose-file {
        display: inline-block;
        padding: 10px 20px;
        background-color: #007bff; /* Bootstrap primary blue color */
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        margin-right: 10px; /* Add some space between buttons */
        }
    
        .choose-file-label {
            display: inline-block; /* Allows setting dimensions */
            background-color: #007bff; /* Button-like blue background */
            color: yellow; /* White text */
            padding: 6px 12px; /* Padding similar to buttons */
            font-size: 16px; /* Font size similar to buttons */
            border-radius: 35px; /* Rounded corners */
            cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
            text-align: center; /* Center the text inside the label */
            margin: 5px; /* Add some margin around the label */
        }
    
        .file-input button.choose-file:hover {
        background-color: #0069d9; /* A darker blue for hover effect */
        }
    

        




/*********************** INFO SECTION *********************************************/    

#infoBox {
    resize: vertical; /* Allows only horizontal resizing */
    width: 140px; /* Approximation for 10 columns, adjust as needed */
    min-height: 130px; /* Sets the minimum height to 130px */
}

.info-section {
    margin-top: 20px;
    background-color: #f0f0f0; /* Light gray background color */
    padding: 10px; /* Add some padding around the content */
    /* Style for the section */
}

.info-section h2 {
    background-color: #003366;
    color: white;
    padding: 10px;
    border-radius: 5px;
}

.info {
    padding: 10px;
    border: 1px solid #ccc;
    border-top: none;
    background-color: #f9f9f9;
}

.info span {
    font-weight: bold;
}


    .info-grid-file {
        display: grid;
        grid-template-columns: 1fr 3fr; /* Create two columns with width proportion 1 vs 3 */
        grid-gap: 7px; /* Space between rows and columns */
        align-items: center; /* Vertically center the content */
    }

    .info-grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 2fr; /* Create two columns with equal width */
        grid-gap: 7px; /* Space between rows and columns */
        align-items: center; /* Vertically center the content */
    }

    .info-label {
        grid-column: 1 / 2; /* Place label in the first column */
        text-align: right; /* Align text to the right */
        padding-right: 0px; /* Spacing between label and the divider */
        font-weight: bold; /* Make the text bold */
    }

    .info-value {
        grid-column: 2 / 3; /* Place value in the second column */
        text-align: left; /* Align text to the left */
        padding-left: 0px; /* Spacing between the divider and value */
    }

    .info-label2 {
        grid-column: 3 / 4; /* Place label in the first column */
        text-align: right; /* Align text to the right */
        padding-right: 0px; /* Spacing between label and the divider */
        font-weight: bold; /* Make the text bold */
    }

    .info-value2 {
        grid-column: 4 / 5; /* Place value in the second column */
        text-align: left; /* Align text to the left */
        padding-left: 0px; /* Spacing between the divider and value */
    }




/*********************** SPINNER *********************************************/    
.spinner-image {
    position: absolute; /* Position the spinner absolutely within the .output-container */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Offset the spinner to the exact center */
    width: 50px; /* Your desired width */
    height: auto; /* Maintain aspect ratio */
}

/* Title and Logo styling */
.title {
    display: flex;
    justify-content: center;
    align-items: center;
}

.audio-logo {
    width: 100px;
    height: auto;
    margin-right: 10px;
}

/* Info section for audio details */
.info-section {
    background-color: #1E3A8A;
    padding: 2px;
    border-radius: 8px;
    color: white;
}

/* Grid for displaying file information */
.info-grid-file {
    display: grid;
    grid-template-columns: max-content auto;
    column-gap: 10px;
}

/* Labels and values for file information */
.info-label {
    text-align: right;
}

.info-value {
    color: #000;
}

/* Container for buttons */
.button-container {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
}

/* Styling for dropdown inputs */
.select-input {
    padding: 10px;
    border-radius: 4px;
}

/* Copy button container */
.copy-button-container {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
}

/* Transcription and information wrapper */
.transcription-and-info-wrapper {
    margin-top: 20px;
}

/* Output container for transcription text */
.output-container textarea {
    width: 100%;
    height: auto;
    resize: none;
    overflow: auto;
}

/* Info grid inside the transcription section */
.info-grid {
    display: grid;
    grid-template-columns: repeat(2, max-content auto);
    column-gap: 10px;
    row-gap: 5px;
}

/* Bottom section container */
.bottom-container {
    margin-top: 20px;
}

.bottom-container .info-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#charCount {
    margin-left: auto;
    font-size: 0.8em;
}