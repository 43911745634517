.modal-background {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: white; /* Adjust text color for better visibility on background */
}


.modal-content-background {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: white; /* Adjust text color for better visibility on background */
}


.form-select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="4" height="5" viewBox="0 0 4 5"><path fill="none" stroke="%23333" d="M2 0L0 2h4z" /></svg>');
    background-repeat: no-repeat;
    background-position: 10px center; /* Align to the left */
    background-size: 8px 10px;
    padding-left: 1.5em; /* Make space for the arrow */
    padding-right: 0.75em; /* Adjust padding on the right if necessary */
    text-indent: 1.5em; /* Shift text to the right */
}
