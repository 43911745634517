/* Update these selectors to include .FormControlField */
.input-wrapper > input, .form-control, .FormControlField {
    display: block;
    border: 2px solid rgb(101, 100, 100);
    font-size: 16px;
    font-weight: bold;
    padding: 7px 10px;
    outline: 2px solid red;
    border-radius: 5px;
    transition: border-color 0.3s ease-in-out;
}

.input-wrapper > input:focus, .form-control:focus, .FormControlField:focus {
    border: 2px solid rgb(5, 127, 203);
    outline: 2px solid red;
    box-shadow: 0 0 5px rgba(135, 206, 250, 0.5); /* Adjusted for lightskyblue */
}




.input-wrapper > label {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;

}
.disabled-style {
    background-color: grey; /* Ensures visibility of the disabled state */
    cursor: not-allowed; /* Changes cursor to indicate non-interactivity */
}

.action-icon.delete {
    cursor: pointer;
    padding: 5px;
    margin: 5px;
}

.action-icon.delete.disabled {
    background-color: grey;
    cursor: not-allowed;
    opacity: 0.6;
}


.action-icon {
    cursor: pointer;
    padding: 5px;
    margin: 5px;
    transition: all 0.3s ease;  
    background-color: blue;  /* Default active background */
    color: white;  /* Ensuring text/icon color contrasts well with the background */
}


.action-icon.disabled {
    background-color: grey;
    cursor: not-allowed;
    opacity: 0.6;
}


.custom-modal-width .modal-dialog {
    max-width: 100px; /* Set your desired width */
}


/*  Below Added by JJ  - BEGIN */ 
input, .form-control {
    border: 1px solid #01101f;
    outline: none;
    box-shadow: none;
    transition: border-color 0.3s ease-in-out;
}

input:focus, .form-control:focus {
    border: 1px solid #80bdff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
/*  Upper Added by JJ  - END */ 