.input-wrapper > input {
    display: block;
    border: 2px solid grey;
    font-size: 16px;
    font-weight: bold;
    padding: 7px 10px;
    outline: 0;
    border-radius: 5px;
}
.input-wrapper > label {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
}

.filter-label span {
    display: none !important;
}

.form-array-border {
    border: 1px solid #333;
    padding: 10px;
    position: relative;
    border-radius: 8px;
    background-color: #dee6ff;
    overflow: hidden;
}

.service-remove {
    right: 0px;
    position: absolute;
    cursor: pointer;
    padding: 5px;
    top: 0px;
    width: 25px;
    height: 25px;
    border-radius: 0px 8px 0px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: red;
}

.service-remove .close-btn {
    color: #fff;
    font-size: 20px;
}

.form-label {
    color: #000;
    font-size: 14px;
}

.form-control[disabled] {
    background-color: #e9ecef; /* Light grey background */
    opacity: 1; /* Override the default opacity for disabled inputs */
    color: #495057; /* Text color */
    cursor: not-allowed; /* Change the cursor to indicate the field is not editable */
}

.form-label {
    font-weight: bold;
}

/* Added 20240817 */ 
.btn-green {
    background-color: #28a745; /* Green color */
    border-color: #28a745;     /* Green color */
    color: white;              /* White text */
}

.btn-green:hover {
    background-color: #218838; /* Darker green on hover */
    border-color: #1e7e34;     /* Darker green border on hover */
}


/* Added 20240803 */ 
.date-picker-container {
    display: flex;
    flex-direction: column;
}

.react-datepicker-wrapper,
.react-date-picker {
    width: 100%;
}

.react-datepicker__input-container,
.react-date-picker__wrapper {
    width: 100%;
}

.react-datepicker__input-container input,
.react-date-picker__inputGroup {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    box-sizing: border-box;
}

.react-datepicker {
    font-size: 16px;
}

.react-date-picker__calendar {
    font-size: 16px;
}


/* Added styles to make the dropdowns scrollable */
.select-dropdown .react-select__menu-list {
    max-height: 150px; /* Adjust this value to change the height */
    overflow-y: auto;
}
