.table-container {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    border-radius: 100px;
    overflow-x: auto; /* Allows horizontal scrolling */
    margin: 20px 0;
    border-radius: 8px;
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
    background-color: #ffffff; /* White background for the table container */
    text-align: center;
    align-items: center;
    justify-content: center;
    align-self: center;
  }
  
  .responsive-table {
    width: 100%; /* Full width to fit container */
    min-width: 800px; /* Minimum width for better readability */
    border-collapse: collapse; /* Collapses borders for no lines */
  }
  
  .responsive-table th,
  .responsive-table td {
    padding: 12px 16px; /* Increased padding for better readability */
    text-align: left;
    transition: background-color 0.3s; /* Transition for hover effects */
  }
  
  .responsive-table th {
    background-color: #1f44a6; /* Primary color for header */
    color: white; /* White text for better contrast */
    font-weight: 600;

  }
    .responsive-table th:nth-child(1){
        border-top-left-radius: 25px;
    } 
    .responsive-table th:last-child{
        border-top-right-radius: 25px;
    } 
  /* Alternating row colors */
  .responsive-table tr:nth-child(even) {
    background-color: #f9f9f9; /* Light grey background for even rows */
    color: #1f44a6;
  }
  
  .responsive-table tr:nth-child(odd) {
    background-color: #ffffff; /* White background for odd rows */
  }
  
  .responsive-table td:hover {
    background-color: #e9ecef; /* Light hover effect on table rows */
  }
  
  .responsive-table td input[type="text"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle inset shadow */
  }
  
  .responsive-table td input[type="checkbox"] {
    transform: scale(1.3); /* Larger checkbox for better visibility */
  }
  
  .responsive-table td button {
    padding: 8px 14px;
    background-color: #007bff; /* Primary button color */
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s, transform 0.2s; /* Transition effects */
  }
  
  .responsive-table td button:hover {
    background-color: #0056b3; /* Darker shade on hover */
    transform: translateY(-2px); /* Slight lift effect */
  }
  
  /* Media Queries for Small Screens */
  @media screen and (max-width: 768px) {
    .responsive-table {
      font-size: 14px;
    }
  
    .responsive-table th,
    .responsive-table td {
      padding: 10px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .responsive-table {
      font-size: 12px;
    }
  
    .responsive-table th,
    .responsive-table td {
      display: block;
      width: 100%;
      box-sizing: border-box;
    }
  
    .responsive-table td {
      padding: 10px 5px;
    }
  
    .responsive-table tr {
      margin-bottom: 10px;
      display: block;
    }
  
    .responsive-table th {
      display: none; /* Hide headers on small screens */
    }
  
    .responsive-table td:before {
      content: attr(data-label);
      font-weight: bold;
      text-transform: uppercase;
      display: block;
      color: #007bff; /* Highlight labels in primary color */
    }
  
    .responsive-table td {
      display: flex;
      justify-content: space-between;
    }
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination button {
    margin: 0 5px;
    padding: 8px 12px;
    border: none;
    background-color: #007bff; /* Primary color for pagination buttons */
    color: white;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s, transform 0.2s; /* Transition effects */
  }
  
  .pagination button:hover {
    background-color: #0056b3; /* Darker shade on hover */
    transform: translateY(-1px); /* Slight lift effect */
  }
  
  .pagination button.active {
    background-color: #0056b3; /* Darker background for active button */
  }
  
  .search-container {
    margin: 20px 0;
    display: flex;
    justify-content: right;
  }
  
  .search-input {
    padding: 10px;
    width: 300px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    transition: border-color 0.3s; /* Transition for focus effect */
  }
  
  .search-input:focus {
    border-color: #007bff; /* Highlight border on focus */
  }
  
  /* Button Styles */
  .button1 {
    background-color: #59ad48 !important;
    color: rgb(147, 229, 229); /* or any dark color */
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 25px !important;
  }
  
  .button1:hover {
    background-color: darkgreen; /* Optional hover effect */
  }
  
  .button2 {
    background-color: #1f44a6 !important;
    color: darkslategray; /* or any dark color */
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 25px !important;
  }
  
  .button2:hover {
    background-color: dodgerblue; /* Optional hover effect */
  }
  
  .custom-checkbox {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
  }
  
  .custom-checkbox input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    background-color: #eee;
    border-radius: 4px;
  }
  
  .custom-checkbox:hover input ~ .checkmark {
    background-color: #ddd;
  }
  
  .custom-checkbox input:checked ~ .checkmark {
    background-color: #2196F3; /* Blue color */
  }
  
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .custom-checkbox input:checked ~ .checkmark:after {
    display: block;
  }
  
  .custom-checkbox .checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  