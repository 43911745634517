.modal-header img {
    width: 15%;
    margin-right: 10px; 
}


.fixed-image {
    /* width: 500px; /* Set the desired width */
    height: 180px; /* Set the desired height */
    object-fit: cover; /* Ensures aspect ratio is preserved */
}


.center-content {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers horizontally */
    justify-content: center; /* Centers vertically */
    text-align: center; /* Ensures the text is centered */
    height: 100%; /* Ensure the container takes the full height of the card */
}
p{
    font-size: 18px;
    color: #5fba46;
    font-weight: bolder;
    margin-bottom: ;
}
img{
    width: 900px;
}