.main {
    display: flex;
    height: 100%;
    margin-top: 100px;
}

.content {
    flex-grow: 1;
    background-color: #f4f4f4;
    margin-left: 40px;
    padding: 0 10px 0 50px;
    transition: margin-left 0.3s ease;
    height: 100%;
    box-sizing: border-box;
}

.sidebar-open .content {
    margin-left: 220px;
}


.sidebar-open {
    margin-left: 220px;
}

.header-container {
    display: flex;

    align-items: center;

    justify-content: space-between;
    margin-top: 24px;
    margin-bottom: 20px;

}

.subscriptions-title {
    margin: 0;

}

.button-group {
    display: flex;

    gap: 10px;

    padding: 10px;

    margin-right: 8%;
    margin-top: 10px;
}

.action-button {
    padding: 10px 15px;

    background-color: #2148b8;

    color: white;

    border: none;

    border-radius: 5px;

    cursor: pointer;

}



.subscriptions-title {

    margin-left: 20px;

    font-size: 32px;

    transition: margin-left 0.3s ease;

    color: #2148b8;
    font-weight: bold;
}

.subscriptions-title.collapsed {
    margin-left: 20px;

}

.subscriptions-title.expanded {
    margin-left: 70px;

}

.subscriptions-title-main {
    text-align: center;
    font: bold;

    margin-left: 10px;

    font-size: 36px;

    transition: margin-left 0.3s ease;

    color: #5fba46
}

.subscriptions-title-main.collapsed {
    margin-left: 10px;

}

.subscriptions-title-main.expanded {
    margin-left: 70px;

}

@media (max-width: 768px) {
    .header-container {
        flex-direction: column;
        align-items: center;

    }

    .button-group {
        flex-direction: column;
        margin-right: 0;
        margin-left: 10px;
        width: 90%;
    }

    .subscriptions-title-main {
        margin-left: 0;
        text-align: center;
    }

    .content {
        margin-left: 5px;
    }
}