.transaction-history-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.back-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 24px;
    margin-bottom: 15px;
    color: #c54f18;
}

.table-container {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.transaction-table {
    width: 80%;
    border-collapse: collapse;
    margin-right: 20px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 3px 4px 8px rgba(0, 0, 0, 0.1);
    font-size: 12px;
}

.transaction-table th,
.transaction-table td {
    border: 1px solid #ccc;
    padding: 4px;
    text-align: left;
}

.transaction-table th {
    background-color: #2148b8;
    color: white;
}

.transaction-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.transaction-table tr:nth-child(odd) {
    background-color: white;
}

.empty-div {
    background-color: #f0f0f0;
    width: 40%;
    min-height: 250px;
    padding: 20px;
}

@media (max-width: 1080px) {



    .table-container {
        flex-direction: column;
        max-width: 100%;
        overflow-x: auto;
    }

    .transaction-table {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
        overflow-x: auto;
    }


    .empty-div {
        min-width: 100%;
    }
}
@media (max-width: 425px) {



    .table-container {
        
        max-width: 235px;
        overflow-x: auto;
    }

    .transaction-table {
        margin-left: 500px;
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
        
    }

}