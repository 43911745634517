.send-remittance-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.section-title {
    font-weight: bold;
    margin-bottom: 10px;
}


.payment-details,
.transfer-details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}


.input-group {
    flex: 1;
    margin-right: 20px;
    margin-bottom: 20px;
}

.input-group-2 {
    flex: 1;
    margin-right: 20px;
    margin-bottom: 20px;
    width: 100%;
}


.input-group:last-child {
    margin-right: 0;
}

.input-group label {
    display: block;
    margin-bottom: 5px;
}

.input-field {
    width: 100%;
    height: 35px;
    border: 1px solid #e0e0e0;
    background-color: #fff;
    border-radius: 5px;
    padding: 5px;
}

.send-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #2148b8;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.back-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 24px;
    margin-bottom: 15px;
    color: #c54f18;
}



.terms-conditions {
    margin-top: 20px;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 8px;
    border: 1px solid #ddd;
}

.terms-conditions h3 {
    font-size: 1rem;
    margin-bottom: 10px;
    color: #333;
}

.terms-list {
    padding-left: 15px;
    list-style-type: decimal;
    color: #555;
}

.terms-list li {
    margin-bottom: 8px;
    font-size: 0.9rem;
}


@media (max-width: 768px) {

    .payment-details,
    .transfer-details {
        flex-direction: column;
        width: 100%;
    }

    .input-group {
        margin-right: 0;
        margin-bottom: 15px;
    }
}

.greyed-out {
    background-color: #f5f5f5;
    color: #999;
    cursor: not-allowed;
  }
  