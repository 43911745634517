.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.popup-content {
    background: white;
    padding: 15px;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    font-size: 14px;
}

.popup-content h2 {
    font-size: 18px;
}

.terms-and-conditions {
    margin-top: 15px;
    padding-left: 15px;
}

.terms-and-conditions p {
    margin: 5px 0;
    padding-left: 0;
    font-size: 12px;
}

.terms-and-conditions ul {
    list-style-type: decimal;
    padding-left: 15px;
    font-size: 12px;
}

.terms-and-conditions .red-text {
    color: red;
    font-weight: bold;
}

.popup-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.popup-button {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    background: #5fba46;
    color: white;
    cursor: pointer;
    font-size: 14px;
}

.confirm-button {
    background: #2148b8;
}

.popup-details {
    margin: 10px 0;
    font-weight: bold;
}

.detail-row {
    display: flex;
    margin: 10px 0;
}

.detail-label {
    color: blue;
    font-weight: bold;
    margin-right: 5px;
    flex-basis: 40%;
    padding-left: 15px;
}

.detail-value {
    flex-grow: 1;
    text-align: left;
    padding-left: 10px;
}