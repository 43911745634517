.beneficiary-form {
    flex: 1;
}

label {
    margin-bottom: 3px;
    font-weight: bold;
}

input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    transition: border 0.3s;
}

input:focus {
    border-color: #2148b8;
    outline: none;
}

.add-button {
    padding: 10px 15px;
    background-color: #2148b8;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background 0.3s;
    margin-bottom: 10px;
    margin-right: auto;
    margin-top: 5px;
}


.form-fields {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.form-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.form-row>div {
    flex: 1;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    margin-right: 10px;
}

.form-row>div:last-child {
    margin-right: 0;
}

label {
    white-space: nowrap;
    margin-bottom: 5px;
    color: #5fba46;
}

input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
}

.delete-icon {
    cursor: pointer;
    color: red;
    font-size: 18px;
}

.add-beneficiary-box {
    background-color: #fff;
    border-radius: 8px;
}

.error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}

.back-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 24px;
    margin-bottom: 15px;
    color: #c54f18;
}

.beneficiary-container {
    display: flex;
    gap: 20px;
}

.beneficiary-form-container {
    flex: 1;
}


.beneficiary-list-container {
    flex: 1;
    background-color: #f9f9f9;
    border-radius: 5px;
    padding: 15px;
    width: 100%;
    margin-left: 20px;
    height: 400px;
    overflow-y: auto;
}

.beneficiary-list-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.beneficiary-list-header h4 {
    margin: 0;
    font-size: 18px;
    color: #2148b8;
}

.beneficiary-list {
    list-style-type: none;
    padding: 0;
}

.beneficiary-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
    transition: background 0.3s;
    cursor: pointer;
}

.beneficiary-item:hover {
    background-color: #f1f1f1;
}

.delete-icon {
    cursor: pointer;
    color: red;
    font-size: 20px;
    transition: color 0.3s;
}

.delete-icon:hover {
    color: darkred;
}

.edit-icon {
    cursor: pointer;
    color: #2148b8;
    font-size: 20px;
    margin-right: 10px;
    transition: color 0.3s;
}

select {
    width: 100%;
    padding: 6px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    color: #333;
    transition: border-color 0.3s ease;
}

select:focus {
    border-color: #2148b8;
    outline: none;
}



.form-row select:disabled {
    background-color: #e9e9e9;
    color: #999;
}


@media (max-width: 768px) {
    .beneficiary-container {
        flex-direction: column;
    }


    .beneficiary-form-container {
        width: 100%;
    }

    .beneficiary-list-container {
        width: 100%;
        padding: 5px;
        margin-left: 0px;
    }

    .beneficiary-form {
        flex: 1;
    }

    .form-fields {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .form-row {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .form-row>div {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin: 0;
    }

    label {
        white-space: nowrap;
        margin-bottom: 5px;
        color: #5fba46;
    }

    input {
        width: 100%;
        padding: 8px;
        box-sizing: border-box;
    }

    .country-option {
        display: flex;
        align-items: center;
    }
    
    .country-flag {
    width: 20px;
    height: 15px;
    margin-right: 8px;
    }
      

}