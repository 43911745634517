.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1);
  transition: width 0.3s ease;
  overflow-x: hidden;
  z-index: 1;
  padding-top: 76px;

}

.sidebar.open {
  width: 220px;
}

.sidebar.closed {
  width: 70px;
}

.hamburger-menu {
  font-size: 24px;

  margin-left: 18px;
  cursor: pointer;
  padding: 15px 0;
  margin-top: 10px;
}

.menu-items {
  list-style: none;
  padding: 0;
  margin: 20px 0;
  color: #2148b8;
}

.menu-items li {
  padding: 15px 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.menu-items li:hover {
  background-color: #f4f4f4;
}

.icon {
  font-size: 24px;
  margin-right: 20px;
}

.sidebar.closed .icon {
  margin-right: 0;
}


@media (max-width: 768px) {
  .sidebar.open {
    width: 180px;
  }

  .sidebar.closed {
    width: 60px;
  }

  .hamburger-menu {
    font-size: 20px;
    padding: 10px 0;
  }

  .menu-items li {
    padding: 10px 15px;
  }

  .icon {
    font-size: 20px;
    margin-right: 10px;
  }

  .sidebar {
    box-shadow: none;
  }


  .menu-items li.open span {
    display: inline;

  }
}

@media (max-width: 480px) {
  .sidebar.open {
    width: 150px;
  }

  .sidebar.closed {
    width: 50px;
  }

  .hamburger-menu {
    font-size: 18px;
  }
}